import React, { useCallback, useRef, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card'
import { Checkbox } from 'primereact/checkbox';
import { Skeleton } from 'primereact/skeleton'
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { BrCalendar } from './BrCalendar';
import { ScrollPanel } from 'primereact/scrollpanel';
import { useFormik } from 'formik';
import { useUserMenu } from '../hooks/useUserMenu';
import * as Yup from 'yup';
import api from '../api/api';
import { ErrorValidationMessage } from './ErrorValidationMessage';
import { Printd } from 'printd';
import { confirmDialog } from 'primereact/confirmdialog';
import logo2 from '../assets/logo_conservatorio.jpg';
import logo3 from '../assets/secretariaCultura.png';


export const FormPerfil = ({ defaultValues }) => {

    const { isLoading } = useUserMenu();
    const history = useHistory();
    const toast = useRef(null);
    const [isSubmiting, setIsSubmiting] = useState(false)
    const [showLGPD, setShowLGPD] = useState(false);
    const [showTermoCompromisso, setShowTermoCompromisso] = useState(false);
    const [showCpfExiste, setShowCpfExiste] = useState(false);
    const [showValidarDados, setShowValidarDados] = useState(false);
    const [menorDeIdade, setMenorDeIdade] = useState(false);
    const [showIdadeInvalida, setShowIdadeInvalida] = useState(false);
    const [showPerfilExiste, setShowPerfilExiste] = useState(false);
    const [showMatriculaExiste, setShowMatriculaExiste] = useState(false);
    const [showComprovante, setShowComprovante] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [perfil, setPerfil] = useState([]);
    const [showInscricaoCampanha, setShowInscricaoCampanha] = useState(false);
    const [inicioInscricoes, setInicioInscricoes] = useState("");
    const [finalInscricoes, setFinalInscricoes] = useState("");
    const [campanhaDados, setCampanhaDados] = useState([]); 
    const d = new Printd();
    const cssText = `
    #print-content {
        font-family: 'Open Sans', sans-serif;
        width: 100%;
        text-align: left;
    }
    
    img {
            margin-top: 25px;
            width: 100%;
            max-width: 250px;
            height: auto;
            display: block;
        }
    
        label {
            font-weight: 300;
        }
    
        span {
            font-weight: 500;
        }
    
        .p-dialog-content {
            padding: 0.5em 2em 2em 2em;
        }
    
        .comprovante-header {
            display: flex;
            justify-content: center;
        }

        .comprovante-header p {
            margin-top: 35px;
            font-size: 1.3em;
            font-weight: bold;
            text-align: center;
        }

        .comprovante-field {
            margin-top: 20px;
            margin bottom: 20px;
        }
    `


    const handlePrintClick = () => {
        d.print(document.getElementById("print-content"), [cssText])
    }

    const header = (
        <div>
            <Button label="Imprimir" className="p-button-secondary" icon="pi pi-print" onClick={handlePrintClick} />
        </div>
    );

    const schema = Yup.object().shape({
        nome: Yup
            .string()
            .required("O nome é obrigatório")
            .max(200, "O nome dete ter no máximo 200 caracteres"),
        cpf: Yup
            .string()
            .required("O CPF é obrigatório"),
        email: Yup
            .string()
            .max(198, "O e-mail deve ter no máximo 198 caracteres")
            .required("O e-mail é obrigatório")
            .email("O e-mail inserido é inválido"),
        dataNascimento: Yup
            .date()
            .max(new Date(), "Data de nascimento inválida")
            .typeError("Uma data deve ser inserida")
            .required("A data de nascimento é obrigatória"),
        celular: Yup
            .string()
            .required("O celular é obrigatório"),
        lgpdAceite: Yup
            .boolean()
            .required("É obrigatório aceitar os termos para prosseguir")
            .oneOf([true], "É obrigatório aceitar os termos para prosseguir"),
        compromissoAceite: Yup
            .boolean()
            .required("É obrigatório aceitar os termos para prosseguir")
            .oneOf([true], "É necessário aceitar o compromisso"),
        responsavel: Yup
            .string()
            .when([], {
                is: () => menorDeIdade,
                then: Yup.string().required("O responsável é obrigatório"),
                otherwise: Yup.string().notRequired(),
            }),

    });


    const formik = useFormik({

        initialValues: defaultValues,

        validationSchema: schema,

        enableReinitialize: true,

        onSubmit: async (data) => {
            setIsSubmiting(true)
            let cpfExiste = await api.get(`perfil/cpf/${data.cpf}`)
            let inscricao = await api.get(`inscricoes/perfil/${cpfExiste.data.id !== undefined ? cpfExiste.data.id : 999999999999999}`)
            let campanha = await api.get(`campanha/ativo`)
            setCampanhaDados(campanha.data);
            try {
                // Se não tem perfil
                if (cpfExiste.data === "") {
                    // console.log(data);
                    data.tipo = "INSCRITO";
                    await api.post("perfil", data)
                    console.log("Criou perfil")
                    let getId = await api.get(`perfil/cpf/${data.cpf}`)
                    const tempInscricao = {
                        'dataInscricao': new Date(),
                        'campanhaId': campanha.data[0].id,
                        'perfilId': getId.data.id,
                        'sorteado': false
                    }
                    data.inscricao = tempInscricao;
                    await api.post("inscricoes", data.inscricao)
                    console.log("Criou inscrição")
                    setPerfil({
                        "id": getId.data.id,
                        "nome": data.nome,
                        'email': data.email,
                        "nascimento": data.dataNascimento,
                        'responsavel': data.responsavel,
                        'celular': data.celular,
                        "cpf": data.cpf,
                        "data": new Date()

                    })
                    formik.resetForm()
                    toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro concluído!', detail: `${data.nome} adicionado(a) com sucesso` });
                    confirmDialog({
                        message: `Deseja salvar um comprovante de inscrição?`,
                        header: 'Confirmação',
                        icon: 'pi pi-exclamation-triangle',
                        acceptLabel: 'Sim',
                        rejectLabel: 'Não',
                        accept: async () => {
                            setShowComprovante(true);
                        },
                        reject: () => { return }
                    });
                    setTimeout(() => {
                        history.push('/')

                    }, 2000)
                }
                // Se possui perfil
                else {
                    // Se não possui inscrição 
                    if (inscricao.data.length === 0) {
                        let getId = await api.get(`perfil/cpf/${data.cpf}`)
                        const response = await api.get(`perfil/${getId.data.id}`)
                        const tempInscricao = {
                            'dataInscricao': new Date(),
                            'campanhaId': campanha.data[0].id,
                            'perfilId': getId.data.id,
                            'sorteado': false
                        }
                        data.inscricao = tempInscricao;
                        await api.post("inscricoes", data.inscricao)
                        console.log("Criou inscrição")
                        const tempPerfil = {
                            'id': response.data.id,
                            'nome': data.nome,
                            'cpf': data.cpf,
                            'email': data.email,
                            'dataNascimento': data.dataNascimento,
                            'responsavel': data.responsavel,
                            'celular': data.celular,
                            'telefone': data.telefone,
                            'lgpdAceite': data.lgpdAceite,
                            'compromissoAceite': data.compromissoAceite,
                            'tipo': response.data.tipo,
                        }
                        data = tempPerfil;
                        await api.put("perfil", data)
                        console.log("Atualizou perfil")
                        setPerfil({
                            "id": data.id,
                            "nome": data.nome,
                            'email': data.email,
                            "nascimento": data.dataNascimento,
                            'responsavel': data.responsavel,
                            'celular': data.celular,
                            "cpf": data.cpf,
                            "data": new Date()
                        })
                        formik.resetForm()
                        toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro concluído!', detail: `${data.nome} adicionado(a) com sucesso` });
                        confirmDialog({
                            message: `Deseja salvar um comprovante de inscrição?`,
                            header: 'Confirmação',
                            icon: 'pi pi-exclamation-triangle',
                            acceptLabel: 'Sim',
                            rejectLabel: 'Não',
                            accept: async () => {
                                setShowComprovante(true);
                            },
                            reject: () => { return }
                        });
                        setTimeout(() => {
                            history.push('/')

                        }, 2000)
                    }
                    // Se possui inscrição 
                    else {
                        const response = await api.get(`perfil/${cpfExiste.data.id}`)
                        const tempInscricao = {
                            'dataInscricao': new Date(),
                            'campanhaId': campanha.data[0].id,
                            'perfilId': cpfExiste.data.id,
                            'sorteado': false
                        }
                        data.inscricao = tempInscricao;
                        await api.post("inscricoes", data.inscricao)
                        console.log("Criou inscrição")
                        const tempPerfil = {
                            'id': response.data.id,
                            'nome': data.nome,
                            'cpf': data.cpf,
                            'email': data.email,
                            'dataNascimento': data.dataNascimento,
                            'responsavel': data.responsavel,
                            'celular': data.celular,
                            'telefone': data.telefone,
                            'lgpdAceite': data.lgpdAceite,
                            'compromissoAceite': data.compromissoAceite,
                            'tipo': response.data.tipo,
                        }
                        data = tempPerfil;
                        await api.put("perfil", data)
                        console.log("Atualizou perfil")
                        setPerfil({
                            "id": data.id,
                            "nome": data.nome,
                            'email': data.email,
                            "nascimento": data.dataNascimento,
                            'responsavel': data.responsavel,
                            'celular': data.celular,
                            "cpf": data.cpf,
                            "data": new Date()
                        })
                        formik.resetForm()
                        toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro concluído!', detail: `${data.nome} adicionado(a) com sucesso` });
                        confirmDialog({
                            message: `Deseja salvar um comprovante de inscrição?`,
                            header: 'Confirmação',
                            icon: 'pi pi-exclamation-triangle',
                            acceptLabel: 'Sim',
                            rejectLabel: 'Não',
                            accept: async () => {
                                setShowComprovante(true);
                            },
                            reject: () => { return }
                        });
                        setTimeout(() => {
                            history.push('/')

                        }, 2000)

                    }
                }

            }
            catch (error) {
                toast.current.show({ life: 2000, severity: 'error', summary: 'Erro na Inscrição!', detail: error.toString() });
            }

            setIsSubmiting(false)

        }

    })

    const handleNascimento = (data) => {
        // console.log(new Date(data.value).toISOString())
        // console.log(new Date("2018/03/31").toISOString())
        // console.log(new Date(data.value).toISOString() > new Date("2018/03/31").toISOString())
        if (new Date().getFullYear() - new Date(data.value).getFullYear() < 18) {
            setMenorDeIdade(true);
        }
        else {
            setMenorDeIdade(false);
        }
        if (new Date(data.value).toISOString() > new Date("2018/03/31").toISOString()) {
            setShowIdadeInvalida(true);
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
    }

    const handleCpfComplete = async (data) => {
        let campanha = await api.get(`campanha/ativo`)
        let cpfExiste = await api.get(`perfil/cpf/${data.value}`)
        let matriculaExiste = await api.get(`matricula/cpf/${data.value}`)
        let inscricao = await api.get(`inscricoes/perfil/${cpfExiste.data.id !== undefined ? cpfExiste.data.id : 999999999999999}`)
        // Se não possui perfil
        if (cpfExiste.data === "") {
            setDisabled(false);
            console.log("Não possui perfil")
        }
        // Se possui perfil
        else {
            // Se possui matricula e a matricula anterior não consta como desistente
            if (matriculaExiste.data.length && matriculaExiste.data[0].status !== "DESISTENTE") {
                setShowMatriculaExiste(true);
                setDisabled(true);
                console.log("Já está matriculado")
            }
            // Se não possui matricula ou a matricula anterior consta como desistente
            else {
                // Se não possui inscrição
                if (inscricao.data.length === 0) {
                    setShowPerfilExiste(true);
                    formik.setFieldValue("nome", cpfExiste.data.nome);
                    formik.setFieldValue("email", cpfExiste.data.email);
                    formik.setFieldValue("dataNascimento", new Date(cpfExiste.data.dataNascimento));
                    formik.setFieldValue("responsavel", cpfExiste.data.responsavel);
                    formik.setFieldValue("celular", cpfExiste.data.celular);
                    formik.setFieldValue("telefone", cpfExiste.data.telefone);
                    formik.setFieldValue("lgpdAceite", cpfExiste.data.lgpdAceite);
                    formik.setFieldValue("compromissoAceite", cpfExiste.data.compromissoAceite);
                    setDisabled(false);
                    console.log("Possui perfil sem inscrição")
                }
                // Se possui inscrição
                else {
                    // Se a campanha da inscrição = campanha ativa
                    if (inscricao.data[0].campanha.id === campanha.data[0].id) {
                        setShowInscricaoCampanha(true);
                        setDisabled(true);
                        console.log("Já se inscreveu, inscrição repetida")
                    }
                    // Se a campanha da inscrição != campanha ativa
                    if (inscricao.data[0].campanha.id !== campanha.data[0].id) {
                        setShowPerfilExiste(true);
                        formik.setFieldValue("nome", cpfExiste.data.nome);
                        formik.setFieldValue("email", cpfExiste.data.email);
                        formik.setFieldValue("dataNascimento", new Date(cpfExiste.data.dataNascimento));
                        formik.setFieldValue("responsavel", cpfExiste.data.responsavel);
                        formik.setFieldValue("celular", cpfExiste.data.celular);
                        formik.setFieldValue("telefone", cpfExiste.data.telefone);
                        formik.setFieldValue("lgpdAceite", cpfExiste.data.lgpdAceite);
                        formik.setFieldValue("compromissoAceite", cpfExiste.data.compromissoAceite);
                        setDisabled(false);
                        console.log("Já se inscreveu, mas na última campanha")
                    }
                }

            }

        }
    }

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const monName = ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"];
    const now = new Date();
    let day = now.getDate();
    let month = now.getMonth();
    let year = now.getFullYear();

    const getDataCampanha = useCallback(async () => {
        try {
            const campanha = await api.get(`campanha/ativo`)
            if (campanha.data.length === 0) {
                setInicioInscricoes("")
                setFinalInscricoes("")
            }
            else {
                setInicioInscricoes(campanha.data[0].dataInicio)
                setFinalInscricoes(campanha.data[0].dataFim)
            }
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter periodo da matricula', detail: error.toString() });
        }
    }, []);

    useEffect(() => {
        async function perfil() {
            await getDataCampanha();
        };
        perfil();

    }, [getDataCampanha])

    return (
        <>
            <Toast ref={toast} position="bottom-right" />
            <Dialog
                visible={showComprovante}
                onHide={() => setShowComprovante(false)}
                header={header}
                className='p-scrollpanel-bar-y'
                breakpoints={{ '960px': '75vw', '640px': '100vw' }}
                style={{ width: '20vw', heigth: '5vw' }}
            >
                <div id="print-content">
                    <div className="comprovante-header">
                        <img
                            id="logo"
                            alt="Logo"
                            src={logo2}
                        />
                    </div>
                    <div className="comprovante-header">
                        <p>Comprovante de inscrição</p>
                    </div>
                    <div className="p-pt-2 p-pb-2 comprovante-field">
                        <p><b>{campanhaDados[0]?.nome}</b></p>
                    </div>
                    <div className="p-pt-2 p-pb-2 comprovante-field">
                        <label>Código: </label>
                        <span>{perfil.id}</span>
                    </div>
                    <div className="p-py-2 comprovante-field">
                        <label>Inscrito: </label>
                        <span>{perfil.nome}</span>
                    </div>
                    <div className="p-py-2 comprovante-field">
                        <label>Email: </label>
                        <span>{perfil.email}</span>
                    </div>
                    <div className="p-py-2 comprovante-field">
                        <label>Data de Nascimento: </label>
                        <span>{new Date(perfil.nascimento).toLocaleDateString('pt-BR')}</span>
                    </div>
                    <div className="p-py-2 comprovante-field">
                        <label>Realizada em: </label>
                        <span>{new Date(perfil.data).toLocaleString('pt-BR')}</span>
                    </div>
                    {perfil.responsavel !== "" ?
                        <div className="p-pt-2 comprovante-field">
                            <label>Responsável: </label>
                            <span>{perfil.responsavel}</span>
                        </div>
                        :
                        ""
                    }
                    <div className="p-py-2 comprovante-field">
                        <label>Celular: </label>
                        <span>{perfil.celular}</span>
                    </div>
                    <div className="p-py-2 comprovante-field">
                        <label>CPF: </label>
                        <span>{perfil.cpf}</span>
                    </div>
                </div>

            </Dialog>
            <Dialog
                visible={showLGPD}
                onHide={() => setShowLGPD(false)} breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '50vw', heigth: '5vw' }}>

                <div className="termo-container">
                    <ScrollPanel className="termo-content">
                        <h1>
                            TERMO DE CONSENTIMENTO PARA TRATAMENTO DE DADOS PESSOAIS LEI GERAL DE PROTEÇÃO DE DADOS PESSOAIS – LGPD
                        </h1>
                        <h4>CLÁUSULA PRIMEIRA</h4>
                        <p>
                            Ao utilizar os serviços, você declara o seu <b>EXPRESSO CONSENTIMENTO</b> para coletarmos, tratarmos e
                            armazenarmos dados sobre você e dependentes com a finalidade de <b>cadastramento escolar</b> com objetivo de
                            mapeamento de demanda de vagas  em nossos cursos e uma melhor alocação/distribuição de alunos e
                            professores.
                        </p>
                        <p>
                            Fica definido que o Conservatório será a <b>CONTROLADORA</b> e o usuário dos serviços será
                            o <b>TITULAR</b> dos dados coletados.
                        </p>
                        <h5>Dados Pessoais</h5>
                        <p>
                            O Titular autoriza a Controladora a realizar o tratamento, ou seja, a utilizar os seguintes dados pessoais
                            e também de seus dependentes, para os fins que serão relacionados na cláusula segunda:
                        </p>
                        <ul>
                            <li>Nome completo;</li>
                            <li>Data de nascimento;</li>
                            <li>Número Cadastro de Pessoas Físicas (CPF);</li>
                            <li>Endereço completo;</li>
                            <li>Números de telefone e endereço de e-mail.</li>
                        </ul>
                        <h4>CLÁUSULA SEGUNDA</h4>
                        <h5>Finalidade do Tratamento dos Dados</h5>
                        <p>O Titular autoriza a Controladora a utilizar os dados pessoais e dados pessoais sensíveis listados neste termo para as seguintes finalidades:</p>
                        <ul>
                            <li>Permitir que a Controladora identifique e entre em contato com o titular, em razão do cadastramento escolar;</li>
                            <li>Para cumprimento, pela Controladora, de obrigações impostas por órgãos de fiscalização;</li>
                            <li>Para a proteção da vida ou da incolumidade física do titular e seus dependentes;</li>
                            <li>Quando necessário para atender aos interesses legítimos da controladora, exceto no caso de prevalecerem direitos e liberdades fundamentais do titular que exijam a proteção dos dados pessoais.</li>
                        </ul>
                        <p>Parágrafo Primeiro: Caso seja necessário o compartilhamento de dados com terceiros que não tenham sido
                            relacionados nesse termo ou qualquer alteração contratual posterior, será ajustado novo termo de
                            consentimento para este fim (§ 6° do artigo 8° e § 2° do artigo 9° da Lei n° 13.709/2018).
                        </p>
                        <p>
                            Parágrafo Segundo: Em caso de alteração na finalidade, que esteja em desacordo com o consentimento original,
                            a Controladora deverá comunicar ao Titular, que poderá revogar o consentimento, conforme previsto na cláusula
                            sexta.
                        </p>
                        <h4>CLÁUSULA TERCEIRA</h4>
                        <h5>Compartilhamento de Dados</h5>
                        <p>
                            A Controladora fica autorizada a compartilhar os dados pessoais do Titular com outros agentes de tratamento
                            de dados, caso seja necessário para as finalidades listadas neste instrumento, desde que, sejam respeitados
                            os princípios da boa-fé, finalidade, adequação, necessidade, livre acesso, qualidade dos dados, transparência,
                            segurança, prevenção, não discriminação e responsabilização e prestação de contas.
                        </p>
                        <h4>CLÁUSULA QUARTA</h4>
                        <h5>Responsabilidade pela Segurança dos Dados</h5>
                        <p>
                            A Controladora se responsabiliza por manter medidas de segurança, técnicas e administrativas suficientes a
                            proteger os dados pessoais do Titular e à Autoridade Nacional de Proteção de Dados (ANPD), comunicando ao
                            Titular, caso ocorra algum incidente de segurança que possa acarretar risco ou dano relevante, conforme
                            artigo 48 da Lei n° 13.709/2018.
                        </p>
                        <h4>CLÁUSULA QUINTA</h4>
                        <h5>Término do Tratamento dos Dados</h5>
                        <p>
                            À Controladora, é permitido manter e utilizar os dados pessoais do Titular durante todo o período
                            contratualmente firmado para as finalidades relacionadas nesse termo e ainda após o término da contratação
                            para cumprimento de obrigação legal ou impostas por órgãos de fiscalização, nos termos do artigo 16 da Lei
                            n° 13.709/2018.
                        </p>
                        <h4>CLÁUSULA SEXTA</h4>
                        <h5>Direito de Revogação do Consentimento</h5>
                        <p>
                            O Titular poderá revogar seu consentimento, a qualquer tempo, por e-mail ou por carta escrita, conforme o
                            artigo 8°, § 5°, da Lei n° 13.709/2018.
                        </p>

                        <h4>CLÁUSULA SÉTIMA</h4>
                        <h5>Tempo de Permanência dos Dados Recolhidos</h5>
                        <p>
                            O titular fica ciente de que a Controladora deverá permanecer com os seus dados pelo período mínimo de
                            guarda de documentos que for necessário para as <b>campanhas de cadastramento do Conservatório</b>.
                        </p>
                        <h4>CLÁUSULA OITAVA</h4>
                        <h5>Vazamento de Dados ou Acessos Não Autorizados – Penalidades</h5>
                        <p>
                            As partes poderão entrar em acordo, quanto aos eventuais danos causados, caso exista o vazamento de dados
                            pessoais ou acessos não autorizados ocorridos comprovadamente por falha de segurança da Controladora, e
                            caso não haja acordo, a Controladora tem ciência que estará sujeita às penalidades previstas no artigo 52
                            da Lei n° 13.709/2018.
                        </p>
                        <br></br>
                        <span>Patos de Minas, {day} de {monName[month]} de {year}.</span>
                    </ScrollPanel>
                </div>
            </Dialog>
            <Dialog
                visible={showTermoCompromisso}
                onHide={() => setShowTermoCompromisso(false)} breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '50vw', heigth: '5vw' }}>

                <div className="termo-container">
                    <ScrollPanel className="termo-content">
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px', margin: '20px 0' }}>
                            <img
                                src={logo3}
                                alt="Logo"
                                style={{ height: '200px', width: 'auto', objectFit: 'contain' }}
                            />
                            <img
                                src={logo2}
                                alt="Logo"
                                style={{ height: '150px', width: 'auto', objectFit: 'contain' }}
                            />
                        </div>

                        <h1 className=''>CONSERVATÓRIO MUNICIPAL“GALDINA CORRÊA DA COSTA
                            RODRIGUES”</h1>
                        <h1>
                            TERMO DE COMPROMISSO
                        </h1>
                        <p>
                            Estou ciente que fui informada e orientada sobre os termos nos quais me dá direito a
                            permanência ou desligamento nas aulas do Conservatório Municipal.
                        </p>
                        <p>Dos principais pontos:</p>
                        <ul>
                            <li>4 faltas injustificadas (atestado ou declaração) e consecutivas perco o direito à vaga
                                de teoria e instrumento;</li>
                            <li>Para permanecer nas aulas de instrumento tenho que estar frequentando aulas de
                                teoria/tca/musicalização e mantendo a média necessária de nota, frequência e
                                aproveitamento de acordo com o regimento interno.</li>
                            <li>Preciso ter 75% de frequência anual que será acompanhada no decorrer do ano e no
                                mínimo 60 pontos para ser aprovada;</li>
                            <li>O desligamento no caso de perda da vaga devido a faltas será feito por e-mail, desta
                                forma mantenha seus dados atualizados na secretaria.</li>
                            <li><a href="https://leismunicipais.com.br/a/mg/p/patos-de-minas/decreto/2019/467/4662/decreto-n-4662-2019-aprova-o-regimento-interno-do-conservatorio-municipal-de-musica-galdina-correa-da-costa-rodrigues" target="_blank" rel="noopener noreferrer">DECRETO Nº 4.662, DE 25 DE JUNHO DE 2019.</a></li>
                        </ul>
                        <br /><br />
                        <span>Patos de Minas, {day} de {monName[month]} de {year}.</span>
                    </ScrollPanel>
                </div>
            </Dialog>
            <Dialog
                visible={showCpfExiste}
                header={"Atenção!"}
                onHide={() => setShowCpfExiste(false)}
                closable={false}
                icon="pi pi-exclamation-triangle"
                breakpoints={{ '960px': '75vw', '640px': '90vw' }}
            >
                <div className="p-d-flex p-flex-row p-ai-center">
                    <i className="pi pi-exclamation-triangle p-mr-4" style={{ 'fontSize': '2.5em' }} />
                    <span style={{ textAlign: 'justify' }}>
                        Uma inscrição já foi realizada com esse CPF!
                    </span>
                </div>
                <Button style={{ float: 'right', marginTop: '12px' }}
                    type="button"
                    label="Ok"
                    onClick={() => setShowCpfExiste(false)} />
            </Dialog>
            <Dialog
                visible={showMatriculaExiste}
                header={"Atenção!"}
                onHide={() => setShowMatriculaExiste(false)}
                closable={false}
                icon="pi pi-exclamation-triangle"
                breakpoints={{ '960px': '75vw', '640px': '90vw' }}
            >
                <div className="p-d-flex p-flex-row p-ai-center">
                    <i className="pi pi-exclamation-triangle p-mr-4" style={{ 'fontSize': '2.5em' }} />
                    <span style={{ textAlign: 'justify' }}>
                        Matricula encontrada! O período de inscrição é reservado somente para alunos ainda não matriculados.
                    </span>
                </div>
                <Button style={{ float: 'right', marginTop: '12px' }}
                    type="button"
                    label="Ok"
                    onClick={() => setShowMatriculaExiste(false)} />
            </Dialog>
            <Dialog
                visible={showPerfilExiste}
                header={"Atenção!"}
                onHide={() => setShowPerfilExiste(false)}
                closable={false}
                icon="pi pi-exclamation-triangle"
                breakpoints={{ '960px': '75vw', '640px': '90vw' }}
            >
                <div className="p-d-flex p-flex-row p-ai-center">
                    <i className="pi pi-exclamation-triangle p-mr-4" style={{ 'fontSize': '2.5em' }} />
                    <span style={{ textAlign: 'justify' }}>
                        Perfil encontrado! Caso deseja atualizar seus dados, edite os campos desejados.
                    </span>
                </div>
                <Button style={{ float: 'right', marginTop: '12px' }}
                    type="button"
                    label="Ok"
                    onClick={() => setShowPerfilExiste(false)} />
            </Dialog>
            <Dialog
                visible={showInscricaoCampanha}
                header={"Atenção!"}
                onHide={() => setShowInscricaoCampanha(false)}
                closable={false}
                icon="pi pi-exclamation-triangle"
                breakpoints={{ '960px': '75vw', '640px': '90vw' }}
            >
                <div className="p-d-flex p-flex-row p-ai-center">
                    <i className="pi pi-exclamation-triangle p-mr-4" style={{ 'fontSize': '2.5em' }} />
                    <span style={{ textAlign: 'justify' }}>
                        É permitido apenas uma inscrição por campanha!
                    </span>
                </div>
                <Button style={{ float: 'right', marginTop: '12px' }}
                    type="button"
                    label="Ok"
                    onClick={() => setShowInscricaoCampanha(false)} />
            </Dialog>
            <Dialog
                visible={showIdadeInvalida}
                header={"Atenção!"}
                onHide={() => setShowIdadeInvalida(false)}
                closable={false}
                icon="pi pi-exclamation-triangle"
                breakpoints={{ '960px': '75vw', '640px': '90vw' }}
            >
                <div className="p-d-flex p-flex-row p-ai-center">
                    <i className="pi pi-exclamation-triangle p-mr-4" style={{ 'fontSize': '2.5em' }} />
                    <span style={{ textAlign: 'justify' }}>
                        Idade inválida! Serão aceitas inscrições de pessoas cuja data de nascimento seja anterior a 31/03/2018.
                    </span>
                </div>
                <Button style={{ float: 'right', marginTop: '12px' }}
                    type="button"
                    label="Ok"
                    onClick={() => setShowIdadeInvalida(false)} />
            </Dialog>
            <Dialog
                visible={showValidarDados}
                header={"Atenção!"}
                onHide={() => setShowValidarDados(false)}
                closable={false}
                icon="pi pi-exclamation-triangle"
                breakpoints={{ '960px': '75vw', '640px': '90vw' }}
            >
                <div className="p-d-flex p-flex-column p-ai-center">
                    <i className="pi pi-exclamation-triangle p-mr-4" style={{ 'fontSize': '2.5em' }} />
                    <h4 style={{ textAlign: 'justify' }}>
                        Confirme se os dados informados estão corretos:
                    </h4>
                    <div>
                        <label>CPF: </label>
                        <span>{formik.values.cpf}</span>
                    </div>
                    <div>
                        <label>Nome: </label>
                        <span>{formik.values.nome}</span>
                    </div>
                    <div>
                        <label>Email: </label>
                        <span>{formik.values.email}</span>
                    </div>
                    <div>
                        <label>Data de Nascimento: </label>
                        <span>{new Date(formik.values.dataNascimento).toLocaleDateString('pt-BR')}</span>
                    </div>
                    {formik.values.responsavel !== "" ?
                        <div className="p-pt-2">
                            <label>Responsável: </label>
                            <span>{formik.values.responsavel}</span>
                        </div>
                        :
                        ""
                    }
                    <div>
                        <label>Celular: </label>
                        <span>{formik.values.celular}</span>
                    </div>
                    <div>
                        <label>Fixo: </label>
                        <span>{formik.values.telefone}</span>
                    </div>
                    <hr />
                </div>
                <Button style={{ float: 'right' }}
                    type="button"
                    label="Revisar"
                    onClick={() => setShowValidarDados(false)} />
                <Button style={{ float: 'right', marginRight: '12px' }}
                    type="button"
                    label="Salvar"
                    onClick={() => {
                        setShowValidarDados(false)
                        formik.handleSubmit()
                    }} />
            </Dialog>
            <form onSubmit={(e) => {
                e.preventDefault()
                setShowValidarDados(true)
            }} className="p-grid p-col-12 p-mx-0">
                <div className="p-col-12">
                    <div className="p-fluid">
                        <h2>FORMULÁRIO DE INSCRIÇÃO</h2>
                        <p>Serão aceitas inscrições de pessoas cuja data de nascimento seja anterior a 31/03/2018.</p>
                        <p>Será permitido apenas uma inscrição por CPF.</p>
                        <p>O prazo de inscrição é de {new Date(inicioInscricoes).toLocaleDateString('pt-BR')} a {new Date(finalInscricoes).toLocaleDateString('pt-BR')}.</p>
                        <div className="p-formgrid p-grid p-pr-0">
                            <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-4">
                                <label
                                    htmlFor="cpf"
                                    className={classNames({ 'p-error': isFormFieldValid('cpf') })}
                                >
                                    CPF
                                </label>
                                {
                                    !isLoading ?
                                        <InputMask
                                            id="cpf"
                                            name="cpf"
                                            mask="999.999.999-99"
                                            unmask={true}
                                            value={formik.values.cpf}
                                            onChange={formik.handleChange}
                                            onComplete={handleCpfComplete}
                                            className={classNames({ 'p-invalid': isFormFieldValid('cpf') })}
                                        />
                                        :
                                        <Skeleton height="35px" />
                                }
                                {formik.errors.cpf && formik.touched.cpf &&
                                    <ErrorValidationMessage message={formik.errors.cpf} />
                                }
                            </div>
                            <div className="p-field p-col-12 p-sm-8">
                                <label htmlFor="nome" className={classNames({ 'p-error': isFormFieldValid('nome') })}>Nome</label>
                                {
                                    !isLoading ?
                                        <InputText
                                            id="nome"
                                            name="nome"
                                            className={classNames({ 'p-invalid': isFormFieldValid('nome') })}
                                            value={formik.values.nome}
                                            onChange={formik.handleChange}
                                        />
                                        :
                                        <Skeleton height="35px" />
                                }
                                {formik.errors.nome && formik.touched.nome &&
                                    <ErrorValidationMessage message={formik.errors.nome} />
                                }
                            </div>
                            <div className="p-field p-col-12 p-sm-8 p-lg-8">
                                <label
                                    htmlFor="email"
                                    className={classNames({ 'p-error': isFormFieldValid('email') })}
                                >
                                    E-mail
                                </label>
                                {
                                    !isLoading ?
                                        <InputText
                                            id="email"
                                            name="email"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('email') })}
                                        />
                                        :
                                        <Skeleton height="35px" />
                                }
                                {formik.errors.email && formik.touched.email &&
                                    <ErrorValidationMessage message={formik.errors.email} />
                                }
                            </div>
                            <div className="p-field p-col-12 p-sm-4 p-md-4">
                                <label
                                    htmlFor="dataNascimento"
                                    className={classNames({ 'p-error': isFormFieldValid('dataNascimento') })}
                                >
                                    Data de nascimento
                                </label>
                                <BrCalendar
                                    id="dataNascimento"
                                    name="dataNascimento"
                                    value={formik.values.dataNascimento}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dataNascimento') })}
                                    yearRange={`1922:2018`}
                                    onSelect={handleNascimento}
                                />
                                {formik.errors.dataNascimento && formik.touched.dataNascimento &&
                                    <ErrorValidationMessage message={formik.errors.dataNascimento} />
                                }
                            </div>
                            <div className="p-field p-col-12 p-sm-6 p-lg-6">
                                <label
                                    htmlFor="responsavel"
                                    className={classNames({ 'p-error': isFormFieldValid('responsavel') })}
                                >
                                    Nome do Resposável (Caso menor de idade)
                                </label>
                                {
                                    !isLoading ?
                                        <InputText
                                            id="responsavel"
                                            name="responsavel"
                                            value={formik.values.responsavel}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('responsavel') })}
                                        />
                                        :
                                        <Skeleton height="35px" />
                                }
                                {formik.errors.responsavel && formik.touched.responsavel &&
                                    <ErrorValidationMessage message={formik.errors.responsavel} />
                                }
                            </div>
                            <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-3">
                                <label
                                    htmlFor="celular"
                                    className={classNames({ 'p-error': isFormFieldValid('celular') })}
                                >
                                    Telefone Celular
                                </label>
                                {
                                    !isLoading ?
                                        <InputMask
                                            id="celular"
                                            name="celular"
                                            unmask={true}
                                            value={formik.values.celular}
                                            mask={formik.values.celular !== undefined && formik.values.celular.length <= 10 ? "(99) 9999-9999?9" : "(99) 99999-9999"}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('celular') })}
                                        />
                                        :
                                        <Skeleton height="35px" />
                                }
                                {formik.errors.celular && formik.touched.celular &&
                                    <ErrorValidationMessage message={formik.errors.celular} />
                                }
                            </div>
                            <div className="p-field p-col-12 p-sm-6 p-md-4 p-lg-3">
                                <label
                                    htmlFor="telefone"
                                    className={classNames({ 'p-error': isFormFieldValid('telefone') })}
                                >
                                    Telefone Fixo
                                </label>
                                {
                                    !isLoading ?
                                        <InputMask
                                            id="telefone"
                                            name="telefone"
                                            mask={formik.values.telefone !== undefined && formik.values.telefone !== null && formik.values.telefone.length <= 10 ? "(99) 9999-9999?9" : "(99) 99999-9999"}
                                            unmask={true}
                                            value={formik.values.telefone}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('telefone') })}
                                        />
                                        :
                                        <Skeleton height="35px" />
                                }
                                {formik.errors.telefone && formik.touched.telefone &&
                                    <ErrorValidationMessage message={formik.errors.telefone} />
                                }
                            </div>
                            <div className="p-field p-col-12">
                                <label
                                    htmlFor="lgpdAceite"
                                    className={classNames({ 'p-error': isFormFieldValid('lgpdAceite') })}
                                    style={{ textAlign: 'justify', textJustify: 'distribute' }}
                                >
                                    <Checkbox
                                        id="lgpdAceite"
                                        name="lgpdAceite"
                                        checked={formik.values.lgpdAceite}
                                        onChange={() => formik.setFieldValue('lgpdAceite', !formik.values.lgpdAceite)}
                                        className={classNames({ 'p-invalid': isFormFieldValid('lgpdAceite') })}
                                        style={{ marginRight: '5px' }}
                                    />
                                    Estou ciente e de acordo com os termos <span style={{ color: "#2196f3" }} onClick={() => setShowLGPD(true)}>(LGPD)</span>.
                                </label>
                                {formik.errors.lgpdAceite && formik.touched.lgpdAceite &&
                                    <ErrorValidationMessage message={formik.errors.lgpdAceite} />
                                }
                            </div>
                            <div className="p-field p-col-12">
                                <label
                                    htmlFor="compromissoAceite"
                                    className={classNames({ 'p-error': isFormFieldValid('compromissoAceite') })}
                                    style={{ textAlign: 'justify', textJustify: 'distribute' }}
                                >
                                    <Checkbox
                                        id="compromissoAceite"
                                        name="compromissoAceite"
                                        checked={formik.values.compromissoAceite}
                                        onChange={(e) => formik.setFieldValue('compromissoAceite', e.checked)}
                                        className={classNames({ 'p-invalid': isFormFieldValid('compromissoAceite') })}
                                        style={{ marginRight: '5px' }}
                                    />

                                    Estou ciente e de acordo com o  <span style={{ color: "#2196f3" }} onClick={() => setShowTermoCompromisso(true)}>Termo de Compromisso</span>.
                                </label>
                                {formik.errors.compromissoAceite && formik.touched.compromissoAceite ? (
                                    <div className="error">{formik.errors.compromissoAceite}</div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-grid p-col-12 p-justify-end p-pb-0 p-pr-0 p-mt-0">

                    {
                        !isLoading ?
                            <Button
                                disabled={disabled}
                                type="submit"
                                label="Enviar"
                                className="p-mt-2"
                                loading={isSubmiting}
                                iconPos="right"
                            />
                            :
                            <Skeleton width="82.16px" height="35px" />
                    }
                </div>

            </form>
        </>
    );
}



